<template>
  <div
    v-show="toasts.length"
    class="fixed end-0 top-9 z-50 flex w-full flex-col gap-4 px-4 md:w-[366px]"
  >
    <TransitionGroup name="toast-list">
      <Toast v-for="toast in toasts" v-bind="toast" :key="toast.id" />
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
// TODO: toast are missing aligment, postion functionality
import { useToast } from "~/composables/Common/useToast";
import Toast from "./Toast.vue";

const { toasts } = useToast();
</script>

<style scoped>
.toast-list-enter-active,
.toast-list-leave-active {
  transition: all 350ms ease-in-out;
}

.toast-list-enter-from,
.toast-list-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

&:where([dir="rtl"], [dir="rtl"] *) {
  .toast-list-enter-from,
  .toast-list-leave-to {
    opacity: 0;
    transform: translateX(-100%);
  }
}
</style>

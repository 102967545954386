
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _404A9m6cHdpbDMeta } from "/home/forge/product-module.34ml.com/pages/404.vue?macro=true";
import { default as accountShXIGItF2kMeta } from "/home/forge/product-module.34ml.com/pages/account.vue?macro=true";
import { default as bundlesHBUc04SUQDMeta } from "/home/forge/product-module.34ml.com/pages/bundles.vue?macro=true";
import { default as cartyvZialW6uXMeta } from "/home/forge/product-module.34ml.com/pages/cart.vue?macro=true";
import { default as indexIhy0Vu3UwzMeta } from "/home/forge/product-module.34ml.com/pages/checkout/index.vue?macro=true";
import { default as contactUsnySZtlMDQ0Meta } from "/home/forge/product-module.34ml.com/pages/contactUs.vue?macro=true";
import { default as _91id_93hACEnqYVcTMeta } from "/home/forge/product-module.34ml.com/pages/custom/[id].vue?macro=true";
import { default as faqY5izV9RD1rMeta } from "/home/forge/product-module.34ml.com/pages/faq.vue?macro=true";
import { default as indexxMP19XNGB3Meta } from "/home/forge/product-module.34ml.com/pages/index.vue?macro=true";
import { default as logincGe3tdnsuAMeta } from "/home/forge/product-module.34ml.com/pages/login.vue?macro=true";
import { default as order_45placedi8LzhHN5W5Meta } from "/home/forge/product-module.34ml.com/pages/order-placed.vue?macro=true";
import { default as contentqJXD4fQwehMeta } from "/home/forge/product-module.34ml.com/pages/pages/[key]/content.vue?macro=true";
import { default as payment_45failedz5YyzmNmDWMeta } from "/home/forge/product-module.34ml.com/pages/payment-failed.vue?macro=true";
import { default as payment_45pendingNIz67T2X5lMeta } from "/home/forge/product-module.34ml.com/pages/payment-pending.vue?macro=true";
import { default as processing_45transactionCtmAvpIl5FMeta } from "/home/forge/product-module.34ml.com/pages/processing-transaction.vue?macro=true";
import { default as _91id_93xBK1job2HeMeta } from "/home/forge/product-module.34ml.com/pages/products/[id].vue?macro=true";
import { default as indexBf1CQmvIwdMeta } from "/home/forge/product-module.34ml.com/pages/products/index.vue?macro=true";
import { default as registerJOXrA2PbvpMeta } from "/home/forge/product-module.34ml.com/pages/register.vue?macro=true";
import { default as resetlqUtg4hddzMeta } from "/home/forge/product-module.34ml.com/pages/reset.vue?macro=true";
import { default as staticFXrzOkbsEYMeta } from "/home/forge/product-module.34ml.com/pages/static.vue?macro=true";
import { default as tenantLogin1RyiPZ1WbXMeta } from "/home/forge/product-module.34ml.com/pages/tenantLogin.vue?macro=true";
import { default as verifymN0eKOSUoSMeta } from "/home/forge/product-module.34ml.com/pages/verify.vue?macro=true";
import { default as wishlistrNxGgx4IwiMeta } from "/home/forge/product-module.34ml.com/pages/wishlist.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/home/forge/product-module.34ml.com/pages/404.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: accountShXIGItF2kMeta || {},
    component: () => import("/home/forge/product-module.34ml.com/pages/account.vue")
  },
  {
    name: "bundles",
    path: "/bundles",
    component: () => import("/home/forge/product-module.34ml.com/pages/bundles.vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/home/forge/product-module.34ml.com/pages/cart.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexIhy0Vu3UwzMeta || {},
    component: () => import("/home/forge/product-module.34ml.com/pages/checkout/index.vue")
  },
  {
    name: "contactUs",
    path: "/contactUs",
    meta: contactUsnySZtlMDQ0Meta || {},
    component: () => import("/home/forge/product-module.34ml.com/pages/contactUs.vue")
  },
  {
    name: "custom-id",
    path: "/custom/:id()",
    meta: _91id_93hACEnqYVcTMeta || {},
    component: () => import("/home/forge/product-module.34ml.com/pages/custom/[id].vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/forge/product-module.34ml.com/pages/faq.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/product-module.34ml.com/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logincGe3tdnsuAMeta || {},
    component: () => import("/home/forge/product-module.34ml.com/pages/login.vue")
  },
  {
    name: "order-placed",
    path: "/order-placed",
    component: () => import("/home/forge/product-module.34ml.com/pages/order-placed.vue")
  },
  {
    name: "pages-key-content",
    path: "/pages/:key()/content",
    component: () => import("/home/forge/product-module.34ml.com/pages/pages/[key]/content.vue")
  },
  {
    name: "payment-failed",
    path: "/payment-failed",
    component: () => import("/home/forge/product-module.34ml.com/pages/payment-failed.vue")
  },
  {
    name: "payment-pending",
    path: "/payment-pending",
    component: () => import("/home/forge/product-module.34ml.com/pages/payment-pending.vue")
  },
  {
    name: "processing-transaction",
    path: "/processing-transaction",
    meta: processing_45transactionCtmAvpIl5FMeta || {},
    component: () => import("/home/forge/product-module.34ml.com/pages/processing-transaction.vue")
  },
  {
    name: "products-id",
    path: "/products/:id()",
    meta: _91id_93xBK1job2HeMeta || {},
    component: () => import("/home/forge/product-module.34ml.com/pages/products/[id].vue")
  },
  {
    name: "products",
    path: "/products",
    meta: indexBf1CQmvIwdMeta || {},
    component: () => import("/home/forge/product-module.34ml.com/pages/products/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerJOXrA2PbvpMeta || {},
    component: () => import("/home/forge/product-module.34ml.com/pages/register.vue")
  },
  {
    name: "reset",
    path: "/reset",
    component: () => import("/home/forge/product-module.34ml.com/pages/reset.vue")
  },
  {
    name: "static",
    path: "/static",
    component: () => import("/home/forge/product-module.34ml.com/pages/static.vue")
  },
  {
    name: "tenantLogin",
    path: "/tenantLogin",
    meta: tenantLogin1RyiPZ1WbXMeta || {},
    component: () => import("/home/forge/product-module.34ml.com/pages/tenantLogin.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/home/forge/product-module.34ml.com/pages/verify.vue")
  },
  {
    name: "wishlist",
    path: "/wishlist",
    component: () => import("/home/forge/product-module.34ml.com/pages/wishlist.vue")
  }
]
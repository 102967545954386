<template>
  <NuxtLoadingIndicator :color="$color" :height="5" />
  <div class="app relative h-full" :class="`theme-${$appConfig ? $theme : 1}`">
    <NuxtLayout>
      <NuxtPage />
      <ToastList />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import useLocale from "./composables/Common/useLocale";
import useGAnalytics from "~/composables/Common/useGAnalytics";
import { useOnline } from "@vueuse/core";
import ToastList from "~/components/common/ToastList.vue";

const isOnline = useOnline();
const { initLocale } = useLocale();
const { getGAnalyticsScript } = useGAnalytics();

useHead(() => ({
  script: getGAnalyticsScript(),
}));

onMounted(() => {
  initLocale();

  watchEffect(() => {
    if (!isOnline.value) {
      throw createError({
        statusCode: 404,
        message: "You are not connected to the internet!",
        fatal: true,
      });
    }
  });
});
</script>

<style>
* {
  --main: v-bind("$color");
  --swiper-scrollbar-drag-bg-color: var(--main);
  --swiper-navigation-color: var(--main);
  --swiper-pagination-color: var(--main);
}
</style>

import { initializeApp } from "firebase/app";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import tenantConfig from "../tenant-config.json";

export default defineNuxtPlugin(() => {
  const apiKey = tenantConfig.firebase ?? "";
  const projectId = tenantConfig.projectId ?? "";
  const appId = tenantConfig.appId ?? "";

  const recaptchaKey = tenantConfig.recaptchaKey ?? "";

  const firebaseConfig =
    recaptchaKey !== "" ? { apiKey, projectId, appId } : { apiKey };

  // Initialize Firebase
  const firebaseApp = initializeApp(firebaseConfig);

  if (recaptchaKey !== "") {
    initializeAppCheck(firebaseApp, {
      provider: new ReCaptchaEnterpriseProvider(recaptchaKey),
      isTokenAutoRefreshEnabled: true,
    });
  }
});

export default defineNuxtRouteMiddleware((to, from) => {
  const { enabled: metaEnabled, trackPage } = useMetaPixel();
  const { enabled: gTagEnabled, trackPage: trackGTagPage } = useGTM();

  const PAGES_TO_TRACK = ["cart", "checkout", "register", "index", "products"];

  // prevent track initial load
  // prevent track same page multiple times
  if (from.name && from.name !== to.name) {
    // at least one of the tracks are enabled
    if (gTagEnabled.value || metaEnabled.value) {
      if (metaEnabled.value) {
        // page name is included in PAGES_TO_TRACK
        if (PAGES_TO_TRACK.includes(to.name?.toString() ?? "")) {
          trackPage();
          trackGTagPage(to.name?.toString() ?? "", to.path);
        }
      }
    }
  }
});

import { ToastOptions } from "~/types/Common/Toasts";

export const useToast = () => {
  const toasts = useState<ToastOptions[]>("toasts", () => []);

  const addToast = (message: string, duration?: number) => {
    const toastOption: ToastOptions = {
      id: Math.ceil(Math.random() * 100000),
      align: "center",
      position: "top",
      duration: duration ?? 3000,
      message,
    };

    toasts.value.push(toastOption);
  };

  const removeToast = (id: number) => {
    toasts.value = toasts.value.filter((toast) => toast.id !== id);
  };

  return {
    toasts,
    addToast,
    removeToast,
  };
};

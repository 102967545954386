<template>
  <div
    class="toast relative flex min-w-full flex-col overflow-hidden rounded-md bg-white shadow-lg"
    @mouseenter="pauseTimer"
    @mouseleave="resumeTimer"
  >
    <div class="flex items-center gap-2 p-3">
      <IconsCheckMark
        v-if="type == 'success'"
        class="h-8 w-8"
        fill-color="green"
      />
      <IconsXIcon
        v-else-if="type == 'error'"
        class="h-8 w-8"
        fill-color="red"
      />
      <IconsInfo v-else class="h-8 w-8" fill-color="black" />

      <p class="w-full text-start font-bold">{{ message }}</p>

      <IconsXIcon class="cursor-pointer" @click="removeToast(id)" />
    </div>

    <div
      class="loader absolute bottom-0 left-0 right-0 h-1 rounded-3xl bg-main"
      :style="{ animationDuration: `${duration}ms` }"
    />
  </div>
</template>

<script setup lang="ts">
import { useToast } from "~/composables/Common/useToast";
const props = withDefaults(
  defineProps<{
    id: number;
    message: string;
    align?: "start" | "center" | "end";
    type?: "success" | "error" | "info";
    position?: "top" | "bottom";
    duration?: number;
  }>(),
  {
    type: "success",
    duration: 1000,
  },
);

const { removeToast } = useToast();
let timeoutId: NodeJS.Timeout;
let remainingTime: number = props.duration;
let startTime: number;

const pauseTimer = () => {
  clearTimeout(timeoutId);
  remainingTime -= Date.now() - startTime;
};

const resumeTimer = () => {
  startTime = Date.now();
  timeoutId = setTimeout(() => removeToast(props.id), remainingTime);
};

onMounted(() => {
  startTime = Date.now();
  timeoutId = setTimeout(() => removeToast(props.id), props.duration);
});

onUnmounted(() => {
  clearTimeout(timeoutId);
});
</script>

<style scoped>
.loader {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  animation: toast-loader linear;
  animation-fill-mode: forwards;
}

.toast:hover .loader {
  animation-play-state: paused;
}

@keyframes toast-loader {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
</style>
